import * as Echarts from 'echarts';
import Qs from 'qs';

// 系统名称
const systemName = 'Buding后台管理系统';

// 全局参数
let paramData = {data: []};

// 分类数据
let categoryData = {data: []};

// echarts图表
let echarts = Echarts;

// 查询字符串解析和序列化字符串
let qs = Qs;

// Home页面Tab标签页
let editableTabs = {
    data: [{
        title: '首页',
        name: '/buding/index',
        close: false
    }]
}
// Home页面当前活动的标签页
let editableTabsValue = {
    data: '/buding/index'
}

// 按钮点击后恢复原来的背景色
function btnResume(event) {
    let target = event.target;
    if (target.nodeName === 'I' || target.nodeName === 'SPAN') {
        target = event.target.parentNode;
    }
    target.blur();
}

export default {
    // data
    systemName,
    paramData,
    categoryData,
    echarts,
    qs,
    editableTabs,
    editableTabsValue,

    // methods
    btnResume,
}